


















































































import UserSettingsModule from '@/store/modules/UserSettingsModule';
import { Component, Vue } from 'vue-property-decorator';
import { INoteLinkingSettings } from '@/store/types/INoteLinkingSettings';

@Component
export default class NotelinkingSettingsPage extends Vue {
  get notelinkingsettings(): INoteLinkingSettings {
    return UserSettingsModule.notelinkingsettings!;
  }

  get linkedNoteCount() {
    return UserSettingsModule.linkednotecount;
  }
}
